import styled, { css } from "styled-components";

import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import { H4 } from "../../../../../../assets/styles/typography";

interface WrapperProps {
  animation?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  z-index: 2;
  animation: ${(props: WrapperProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
  overflow: hidden;
`;

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Side = styled.div`
  position: absolute;
  width: 100%;
  height: calc(40vh - 72px);
  padding: 24px;
  top: auto;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #013cff;
`;

export const Title = styled.h2`
  ${H4}
  margin: 0;
  margin-bottom: 16px;
  color: #ffffff;
`;

export const Description = styled.div`
  ${H4}
  margin: 0;
  color: #151515;
`;
