import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import { Wrapper, MainWrapper, Side, Title, Description } from "./style";
import BackgroundSlider from "../../../../../common/ImageSlider";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageChange } from "../../../../../common/Scroll";
import { PageTitle } from "../../../../../common/PageTitle";

interface JoinOurTeamProps {
  pageChange: PageChange;
}

const JoinOurTeam: React.FC<JoinOurTeamProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");
  const data = useStaticQuery(imagesQuery);

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`joinOurTeam_subtitle`))} />
      <Wrapper animation={pageChange}>
        <MainWrapper>
          <BackgroundSlider images={data.allFile.nodes} />
        </MainWrapper>
        <Side>
          <Title>{fixConjunctions(t(`joinOurTeam_title`))}</Title>
          <Description>
            {fixConjunctions(t(`joinOurTeam_subtitle`))}
          </Description>
        </Side>
      </Wrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "joinOurTeam" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default JoinOurTeam;
